@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Excon";
  src: url("./assets/fonts/Excon-Variable.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
