.section1-background-h {
  background-image: url("../../public/images/health-section1-bg.png");
  background-repeat: no-repeat;
  width: 100%;
  background-position: top center;
  height: auto; /* Adjust based on content or specific design needs */
  min-height: 200px; /* Ensures that the bottom image has space if the content is not enough */
  background-size: cover;
}

.section2-background-h {
  background-image: url("../../public/images/health-section2-bg.png");
  background-repeat: no-repeat;
  width: 100%;
  background-position: right center;
  height: auto; /* Adjust based on content or specific design needs */
  min-height: 200px; /* Ensures that the bottom image has space if the content is not enough */
  background-size: cover;
}

.section5-background-h {
  background-image: url("../../public/images/health-section-5-bg.png");
  background-repeat: no-repeat;
  width: 100%;
  background-position: left center;
  height: auto; /* Adjust based on content or specific design needs */
  min-height: 200px; /* Ensures that the bottom image has space if the content is not enough */
  background-size: cover;
}
