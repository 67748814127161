.section1-background {
  background-image: url("../../public/images/landing-section-1-bg.png");
  background-repeat: no-repeat;
  width: 100%;
  background-position: top center;
  height: auto; /* Adjust based on content or specific design needs */
  min-height: 200px; /* Ensures that the bottom image has space if the content is not enough */
  background-size: cover;
}

.section3-background {
  background-image: url("../../public/images/landing-section-3-bg.png");
  background-repeat: no-repeat;
  width: 100%;
  background-position: right center;
  height: auto; /* Adjust based on content or specific design needs */
  min-height: 200px; /* Ensures that the bottom image has space if the content is not enough */
  background-size: auto;
}

.section4-background {
  background-image: url("../../public/images/landing-section-4-bg.png");
  background-repeat: no-repeat;
  width: 100%;
  background-position: center;

  height: auto; /* Adjust based on content or specific design needs */
  min-height: 200px; /* Ensures that the bottom image has space if the content is not enough */
  background-size: cover;
}

.section5-background {
  background-image: url("../../public/images/landing-section-5-bg.png");
  background-repeat: no-repeat;
  width: 100%;
  background-position: left center;
  height: auto; /* Adjust based on content or specific design needs */
  min-height: 200px; /* Ensures that the bottom image has space if the content is not enough */
  background-size: auto;
}

.section6-background {
  background-image: url("../../public/images/landing-section-6-bg.png");
  background-repeat: no-repeat;
  width: 100%;
  height: auto; /* Adjust based on content or specific design needs */
  min-height: 200px; /* Ensures that the bottom image has space if the content is not enough */
  background-size: cover;
}
