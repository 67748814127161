.section1-background-c {
  background-image: url("../../public/images/creators-section1-bg.png");
  background-repeat: no-repeat;
  width: 100%;
  background-position: top center;
  height: auto; /* Adjust based on content or specific design needs */
  min-height: 200px; /* Ensures that the bottom image has space if the content is not enough */
  background-size: cover;
}

.section4-background-c {
  background-image: url("../../public/images/creators-section4-bg.png");
  background-repeat: no-repeat;
  width: 100%;
  background-position: top center;
  height: auto; /* Adjust based on content or specific design needs */
  min-height: 200px; /* Ensures that the bottom image has space if the content is not enough */
  background-size: cover;
}

.section5-background-c {
  background-image: url("../../public/images/creators-section5-bg.png");
  background-repeat: no-repeat;
  width: 100%;
  background-position: top center;
  height: auto; /* Adjust based on content or specific design needs */
  min-height: 200px; /* Ensures that the bottom image has space if the content is not enough */
  background-size: cover;
}

.section6-background-c {
  background-image: url("../../public/images/creators-section6-bg.png");
  background-repeat: no-repeat;
  width: 100%;
  background-position: top center;
  height: auto; /* Adjust based on content or specific design needs */
  min-height: 200px; /* Ensures that the bottom image has space if the content is not enough */
  background-size: cover;
}
