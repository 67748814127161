.sales-section1-background {
  background-image: url("../../public/images/sales-section-1-bg.png");
  background-repeat: no-repeat;
  width: 100%;
  background-position: top center;
  height: auto; /* Adjust based on content or specific design needs */
  min-height: 200px; /* Ensures that the bottom image has space if the content is not enough */
  background-size: cover;
}

.sales-section4-background {
  background-image: url("../../public/images/sales-section-4-bg.png");
  background-repeat: no-repeat;
  width: 100%;
  background-position: right center;
  height: auto; /* Adjust based on content or specific design needs */
  min-height: 200px; /* Ensures that the bottom image has space if the content is not enough */
  background-size: auto;
}

.sales-section5-background {
  background-image: url("../../public/images/sales-section-5-bg.png");
  background-repeat: no-repeat;
  width: 100%;
  height: auto; /* Adjust based on content or specific design needs */
  min-height: 200px; /* Ensures that the bottom image has space if the content is not enough */
  background-size: cover;
}

.sales-section6-background {
  background-image: url("../../public/images/sales-section-6-bg.png");
  background-repeat: no-repeat;
  width: 100%;
  height: auto; /* Adjust based on content or specific design needs */
  min-height: 200px; /* Ensures that the bottom image has space if the content is not enough */
  background-size: cover;
}

.sales-section7-background {
  background-image: url("../../public/images/sales-section-7-bg.png");
  background-repeat: no-repeat;
  width: 100%;
  height: auto; /* Adjust based on content or specific design needs */
  min-height: 200px; /* Ensures that the bottom image has space if the content is not enough */
  background-size: cover;
}

.sales-section8-background {
  background-image: url("../../public/images/sales-section-8-bg.png");
  background-repeat: no-repeat;
  width: 100%;
  height: auto; /* Adjust based on content or specific design needs */
  min-height: 200px; /* Ensures that the bottom image has space if the content is not enough */
  background-size: cover;
}
